import {patchState, signalStore, withMethods, withState} from "@ngrx/signals";
import {User} from "../../user/user";

export interface UserStateInterface {
  isAuthenticated: boolean
  user: User | null
  accessToken?: string | null
}

export const defaultAuthState = {
  isAuthenticated: false,
  user: null,
  accessToken: null,
} as UserStateInterface

export const UserAuthState = signalStore({providedIn: 'root'},
  withState<UserStateInterface>(defaultAuthState),
  withMethods((store) => ({
    setAuthenticated(state: UserStateInterface) {
      return patchState(store, state)
    },
    logout() {
      return patchState(store, defaultAuthState)
    }
  }))
);
